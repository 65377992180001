import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const HowtoRegister = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '5 sencillos pasos ',
    paragraph: 'para registrarte y hacer tu primera inversión en la plataforma Quantum.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Paso 1
                  </div>
                <h3 className="mt-0 mb-12">
                Registrarse en Quantum
                  </h3>
                <p className="m-0">
                Ingresa a la pantalla de registro de Quantum clicando en el botón "Registrarse" que está a continuación. 
                Rellena los campos del formulario y haz clic en "Enviar".
                  </p>
                  <Button tag="a" color="primary" wideMobile href="https://office.myquantum.world/register/sinreferido?side=l">Registrarme Ahora </Button>

              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/registro.PNG')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Paso 2
                  </div>
                <h3 className="mt-0 mb-12">
                Crear la factura
                  </h3>
                <p className="m-0">
                Cuando ingreses, puedes crear tu primera factura para activar el paquete de Quantum:
En el campo "Enter the amount…" ingresa la cantidad de inversión que quieres realizar y haz clic en "Crear Factura".
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/quantum-crear-factura.webp')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Paso 3
                  </div>
                <h3 className="mt-0 mb-12">
                Billetera digital
                  </h3>
                <p className="m-0">
                    Para realizar el pago de la factura, debes transferir el dinero desde tu billetera digital.
                    Para esto te recomedamos Binance. <Link to="https://www.youtube.com/watch?v=aKwUtdNdSdw">Aquí tienes un tutorial para registrarte</Link> y comprar Bitcoins en esta plataforma.
                    Si ya tienes una billetera digital puedes omitir este paso.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/quantum-binance.webp')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Paso 4
                  </div>
                <h3 className="mt-0 mb-12">
                Pagar la factura
                  </h3>
                <p className="m-0">
                  Después de crear la factura, te aparecerá un modal con las direcciones de las billeteras de Quantum.
                  Puedes enviar la cantidad a pagar a la billetera BTC o a la billetera USDT y realizar el pago con cualquiera de estas dos criptomonedas.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/deposito.PNG')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Paso 5
                  </div>
                <h3 className="mt-0 mb-12">
                Plan adquirido
                  </h3>
                <p className="m-0">
                  Cuando hayas transferido el dinero a alguna de las dos billeteras de Quantum (en BTC o USDT), en la pantalla principal verás reflejado el “Plan adquirido” por el monto de tu factura.
                  Esta es tu primera inversión en Quantum. ¡Felicidades!
                  A partir de 72 horas empezarás a generar ganancias automáticamente.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/quantum-plan-adquirido.webp')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

HowtoRegister.propTypes = propTypes;
HowtoRegister.defaultProps = defaultProps;

export default HowtoRegister;