import React from 'react';
// import sections
import HowtoRegister from '../components/sections/HowtoRegister';


const Home = () => {

  return (
    <>
      <HowtoRegister/>
    </>
  );
}

export default Home;