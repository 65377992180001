import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Inicia tu Membresia',
    paragraph: 'Opciónes de membresía.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <h6>PLAN CONSULTOR</h6>
                  <h3>$ 0.5 % 
                <span className="text-sm mb-0" style={{"color":"#5658dd"}}> Ganancias Diarias</span>
                </h3>
                <div class="price-body pt-8">
                  <ul class="pricing-list list-unstyled">
                    <li> <i class="icon icon-check-2-2"></i> Ningun Costo Mensual</li>
                    <li> <i class="icon icon-check-2-2"></i> Inversion Minima 100 USD</li>
                    <li> <i class="icon icon-check-2-2"></i> Retiros desde 50 USD</li>
                    <li> <i class="icon icon-check-2-2"></i> Los Depositos se realizan en BTC o USDT </li>
                  </ul>
                </div>
             
                </div>
                <Button tag="a" color="primary" wideMobile href="https://office.myquantum.world/register/sinreferido?side=l">
                  Quiero Este
                </Button>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <h6>PLAN EJECUTIVO</h6>
                <h3>$ 1.5 % 
                <span className="text-sm mb-0" style={{"color":"#5658dd"}}> Ganancias Diarias</span>
                </h3>
                <div class="price-body">
                  <ul class="pricing-list list-unstyled">
                    <li> <i class="icon icon-check-2-2"></i> Costo Mensual 5% de tu Inversión</li>
                    <li> <i class="icon icon-check-2-2"></i> Inversión Minima 100 USD</li>
                    <li> <i class="icon icon-check-2-2"></i> Retiros desde 50 USD</li>
                    <li> <i class="icon icon-check-2-2"></i> Los Depositos se realizan en BTC o USDT </li>
                  </ul>
                </div>
                </div>
                <Button tag="a" color="primary" wideMobile href="https://office.myquantum.world/register/sinreferido?side=l">
                  Quiero Este
                </Button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;